.camera-page {
  max-width: 898px;
  margin: auto;
  width: 100vw;
  height: 100%;
  position: relative;
}

.camera-page .capture-button {
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%) scale(1);
}

.camera-page .capture-button.has-active-touch {
  transform: translateX(-50%) scale(1.2);
}

.camera-page .gallery-button {
  position: absolute;
  bottom: 25px;
  left: 44px;
}

@media screen and (min-width: 768px) {
  .camera-page .gallery-button {
    left: 8px;
  }
}

.camera-page .switch-button {
  position: absolute;
  bottom: 25px;
  right: 44px;
}

.camera-page ~ footer {
  display: none;
}

.canvas-background {
  background: var(--dark-grey);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
